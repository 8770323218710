import { useState } from "react";
import styles from "./styles.module.css"
import api, { setAuthorization } from "../../api";
import Swal from "sweetalert2";
import { useNavigate } from  "react-router-dom";
export function Login() {


    const navigate = useNavigate()

    async function logar(user: string, password: string, remeber: boolean) {

        Swal.fire({
            title: "Aguarda",
            html: "Logando no sistema...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        return api.post("/login-admin", {
            user,
            password
        })
        .then(response => {

            if(!response?.data?.token) {
                throw new Error(response.data)
            }

            setAuthorization(response?.data?.token)
            
            if(remeber) {
                localStorage.setItem('auth', JSON.stringify(response.data))
            }

            navigate("/liberacao")

            return response;
        })
        .catch(err => {

            Swal.fire("Login", "Erro ao realizar o login, verifique seu usuário e senha!")
        })
        
        .finally(() => {
            if(Swal.isLoading()) {
                Swal.close()
            }
        })

    }
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        
        if(email === "" || password === "") {
            Swal.fire("Preencha todos os campos");
            return;
        }
        
        logar(email, password, true)
    };
  
    return (
        <div className={styles.loginContainer}>
        <form className={styles.loginForm} onSubmit={handleSubmit}>
            <h2>Login</h2>
            <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor="password">Senha</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                />
            </div>

            <button type="submit">Entrar</button>

        </form>
    </div>
    );
}