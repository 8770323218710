import React, { useEffect, useState } from 'react';
import SideMenu from '../side-menu';
import styles from "./styles.module.css"
import api from '../../api'
import Load from '../load';
import { TbLockCancel, TbLockCheck } from "react-icons/tb";
import { ToastContainer, toast } from 'react-toastify';

interface Category {
    id?: number;
    descricao: string;
    status?: 'BLOQUEADO' | 'LIBERADO'
}

function Categoria() {
    const [description, setDescription] = useState<string>('');
    const [categories, setCategories] = useState<Category[]>([]);
    const [load, setLoading] = useState(false);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Aqui você pode enviar os dados do formulário para o backend
        const newCategory: Category = {
            descricao: description
        };

        save(newCategory)
        // Limpar o campo de descrição após o envio
        setDescription('');
    };

    function handleTogleStatus(id: number | undefined, index: number) {

        setLoading(true)
        api.get(`/togle-status-category/${id}`)
            .then(() => {

                api.get('/categorias', {
                }).then(retorno => {
                    setCategories(retorno.data)
                }).catch(error => {
                    if (error.name === 'CanceledError') return;
        
                    toast("Erro ao conectar no servidor!")
                    setCategories([])
                }).finally(() => {
                    setLoading(false)
                })
        

            })
            .catch((error) => {
                toast("Erro ao atualizar status da categoria!")
                setLoading(false)
            })


    }

    function save(value: Category) {

        setLoading(true)
        api.post('/categoria', value).then(
            retorno => {
                if (retorno.status === 201) {
                    setCategories([...categories, retorno.data])
                }
            }
        ).catch(error => {
            alert('Erro ao gravar categoria!')
        }).finally(() => {
            setLoading(false)
        })
    }


    useEffect(() => {

        const controller = new AbortController();
        api.get('/categorias', {
            signal: controller.signal
        }).then(retorno => {
            setCategories(retorno.data)
        }).catch(error => {
            if (error.name === 'CanceledError') return;

            alert("Erro ao conectar no servidor!")
            setCategories([])
        })

        return () => {
            controller.abort()
        }

    }, [])

    return (
        <div className='container'>
            <SideMenu />

            {load ?
                <Load/>
                :
                <div className={styles.container}>
                    <div>
                        <h2 className={styles.title}>Cadastro de Categoria</h2>
                        <div className={styles.formContainer}>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="description">Descrição:</label>
                                <input
                                    type="text"
                                    id="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <button type="submit">Cadastrar</button>
                            </form>
                        </div>
                    </div>


                    <div className={styles.containerList}>
                        <h2 className={styles.title}>Categorias Cadastradas</h2>


                        {categories.map((category, index) => (


                            <div key={category.id} className={`${styles.descRow} ${category.status === 'BLOQUEADO' ? styles.blocked : ''}`}>

                                <span>{category.descricao}</span>

                                <button onClick={() => handleTogleStatus(category.id, index)} className={styles.buttonStatus}>
                                    {
                                        category.status === 'LIBERADO' ?
                                            <TbLockCancel color='#800' /> :
                                            <TbLockCheck color='white' />
                                    }
                                </button>
                            </div>

                        ))}
                    </div>
                </div>

            }
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};

export default Categoria;