import React, { useState } from 'react';
import styles from "./styles.module.css"
import { Link } from 'react-router-dom';
import { CgUnblock } from "react-icons/cg";
import { FcViewDetails } from "react-icons/fc";
import { GiHamburgerMenu } from "react-icons/gi";

const SideMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${styles.sidenav} ${isOpen ? styles.open : ''}`}>
            <div className={styles.menuToggle} onClick={toggleMenu}>
                <GiHamburgerMenu  />
            </div>
           {/*  <div className={styles.userIcon}>
                <i className="fas fa-user"></i>
            </div> */}


                <Link to={'/liberacao'}>
                    <div className={styles.itemMenuContainer}>
                        <CgUnblock fontSize={22} color={'#fff'} />
                        <span className={isOpen ?  '': styles.textMenuClose}>Liberação</span>
                    </div>
                </Link>
                <Link to={'/categoria'}>
                    <div className={styles.itemMenuContainer}>
                        <FcViewDetails fontSize={22}  color={'#fff'}/>
                        <span className={isOpen ?  '': styles.textMenuClose}>Categorias</span>
                    </div>
                </Link>

            
            <div className={styles.logout}>
                <Link to={'/'}>
                <a href="#">Sair <i className="fas fa-sign-out-alt"></i></a>
                </Link>
            </div>
        </div>
    );
};

export default SideMenu;