import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import SideMenu from "../../side-menu";
import Load from "../../load";
import styles from '../styles.module.css';

interface UserData {
    usuarioFK?: number;
    nome?: string;
    razaoSocial?: string;
    CPF?: string;
    CNPJ?: string;
    telefone?: string;
    whatsapp?: string;
    instagram?: string;
    site?: string;
    descricao?: string;
    longitude?: number;
    latitude?: number;
}



export function DadosNegocio( {
    handleChange, handleSubmit, formValues
}: {
    handleChange: any, handleSubmit: any, formValues: any
}) {
   



    return (


        <form onSubmit={handleSubmit} >

                        <div className={styles.formContainer}>
                        <label>
                            Nome:<br/>
                            <input type="text" name="nome" value={formValues.nome} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Razão Social:<br/>
                            <input type="text" name="razaoSocial" value={formValues.razaoSocial} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            CPF:<br/>
                            <input type="text" name="CPF" value={formValues.CPF} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            CNPJ:<br/>
                            <input type="text" name="CNPJ" value={formValues.CNPJ} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Telefone:<br/>
                            <input type="text" name="telefone" value={formValues.telefone} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Whatsapp:<br/>
                            <input type="text" name="whatsapp" value={formValues.whatsapp} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Instagram:<br/>
                            <input type="text" name="instagram" value={formValues.instagram} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Site:<br/>
                            <input type="text" name="site" value={formValues.site} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                            Descrição:<br/>
                            <textarea name="descricao" value={formValues.descricao} onChange={handleChange} />
                        </label>
                        
                        {/*<br />
                        <label>
                        Longitude:
                        <input type="text" name="longitude" value={formValues.longitude} onChange={handleChange} />
                        </label>
                        <br />
                        <label>
                        Latitude:
                        <input type="text" name="latitude" value={formValues.latitude} onChange={handleChange} />
                        </label> */}
                        </div>
                    </form>
    )
}