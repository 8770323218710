import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import SideMenu from "../side-menu";
import Load from "../load";
import styles from './styles.module.css';
import { Categorias } from "./categorias";
import api from "../../api";
import { DadosNegocio } from "./dados-negocio";
import Swal from "sweetalert2";
import { Galeria } from "./galeria";

interface UserData {
    usuarioFK?: number;
    nome?: string;
    razaoSocial?: string;
    CPF?: string;
    CNPJ?: string;
    telefone?: string;
    whatsapp?: string;
    instagram?: string;
    site?: string;
    descricao?: string;
    longitude?: number;
    latitude?: number;
}

type CategoryModel = {
    id: number
    descricao: string
    status?: boolean
}

export function Detalhes() {

    const [loading, setLoading] = useState(false)
    const { id } = useParams<{ id: string }>();
    const [formValuesCostureia, setFormValuesCostureira] = useState<UserData | null>(null);
    const [categorias, setCategorias] = useState<CategoryModel[]>([])
    const [ categoriasAtivas, setCategoriasAtivas] = useState<CategoryModel[]>([])
    const [galeria, setGaleira] = useState<any[]>([])
    const [ nVerificaCategoria, setNVerificaCategoria] = useState(false)
    const [tab, setTab] = useState<'categoria'|'dados'| 'galeria'>('dados')

    

    const handleUpdateCostureira = async () => {
        

        Swal.fire({
            title: "Atualizando", 
            html: "Aguarde enquanto estamos atualizando o cadastro...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }

        })


        api.post(`costureira/${id}`, {
            costureira: formValuesCostureia,
            categorias
        })
        .then(response => {

            setNVerificaCategoria(false)
            setFormValuesCostureira({
                "nome": response.data.nome,
                "razaoSocial": response.data.razaoSocial,
                "CPF": response.data.CPF,
                "CNPJ": response.data.CNPJ,
                "telefone": response.data.telefone,
                "whatsapp": response.data.whatsapp,
                "instagram": response.data.instagram,
                "site": response.data.site,
                "descricao": response.data.descricao,
            })

            setCategoriasAtivas(response.data.Categorias)

            setGaleira(response?.data?.Galeria)


        })
        .catch(error => {
            Swal.fire("Erro ao atualizar dados! Tente novamente ou entre em contato com seu administrador.")
        })
        .finally(() => {
            if(Swal.isLoading()) {
                Swal.close()
            }
        })

    }
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setFormValuesCostureira({
            ...formValuesCostureia,
            [name]: value
        });
    };

    function handleCheckbox(checked: boolean, index: number) {

        let aux = [...categorias]

        aux[index].status = checked

        setCategorias(aux)

    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        // Aqui você pode enviar os dados atualizados para o backend
        console.log("Dados atualizados:", formValuesCostureia);
        // Adicione sua lógica para enviar os dados para o servidor aqui
    };

    useEffect(() => {

        
        api.get(`costureira/${id}`)
            .then(response => {

                setFormValuesCostureira({
                    "nome": response.data.nome,
                    "razaoSocial": response.data.razaoSocial,
                    "CPF": response.data.CPF,
                    "CNPJ": response.data.CNPJ,
                    "telefone": response.data.telefone,
                    "whatsapp": response.data.whatsapp,
                    "instagram": response.data.instagram,
                    "site": response.data.site,
                    "descricao": response.data.descricao,
                })

                setCategoriasAtivas(response.data.Categorias)
                setGaleira(response?.data?.Galeria)

            })
            .catch(error => console.error('Erro ao buscar dados do usuário:', error))
            .finally(() => {
                setLoading(false)
            })
    }, []);

    useEffect(() => {

        api.get('/categorias').then(retorno => {
            retorno.status === 200 ? 
            setCategorias(retorno.data.map( (v: any) => {
                v.status = false
                return v
            }))
                : 
            Swal.fire('Erro ao buscar descrições.')


            setCategorias(retorno.data.map( (v: any) => {
                v.status = false
                return v
            }))
        }).catch(error => {
            Swal.fire('Erro ao buscar descrições.')
        })

    }, [])


    useEffect(() => {

        if(categorias.length === 0 || categoriasAtivas.length === 0 || nVerificaCategoria) {
            return
        }

        setNVerificaCategoria(true)
        marcarCategoriasAtivas()

    }, [categorias, categoriasAtivas])


    const marcarCategoriasAtivas = () => {


        let auxCategoriasSelecionadas = categorias.map(c => {

            if(categoriasAtivas.find(c2 => c2.id === c.id ) !== undefined) {
                c.status = true
            }

            return c


        })

        setCategorias(auxCategoriasSelecionadas)

    }


    if (!formValuesCostureia) {
        return <Load />;
    }

    return (

        <div className='container'>
            <SideMenu />
            <div className={styles.detalhes}>


                <div>
                    <div className={styles.header}>
                        <h1>Editar Cadastro</h1>
                        <button type="button" onClick={handleUpdateCostureira}>Salvar</button>

                    </div>
                    <div className={ styles.tab }>
                        <h3 onClick={() => setTab('dados')} className={ tab === 'dados' ? styles.selectedTab : ''}>Dados da Costureira</h3>
                        <h3 onClick={() => setTab('categoria')} className={ tab === 'categoria' ? styles.selectedTab : ''}>Categorias</h3>
                        <h3 onClick={() => setTab('galeria')} className={ tab === 'galeria' ? styles.selectedTab : ''}>Galeria</h3>
                    </div>
                    <br />
                    {
                        tab === "dados" &&
                        <DadosNegocio handleSubmit={handleSubmit} handleChange={handleChange} formValues={formValuesCostureia} />
                    }

                    {
                        tab === "categoria" &&
                        <Categorias handleCheckbox={handleCheckbox} categorias={categorias} />
                    }

{
                        tab === "galeria" && galeria?.length > 0 &&
                        <Galeria galeria={galeria} />
                    }
                </div>


            </div>
        </div>
    )
}