import { useEffect } from "react"
import { CategoriesContainer, CategoriesGroup, CategoryText, ScrollCategory, Container } from "./styles"

export function Categorias({
    handleCheckbox, categorias
}: {
    handleCheckbox: any, categorias: any
}) {


    return(
        <Container>
            <ScrollCategory>
            <CategoriesContainer>

                {categorias.map((category: any, index: any) => (
                    <CategoriesGroup key={category.id}>
                    <CategoryText>{category.descricao}</CategoryText>
                    <input type="checkbox" onChange={(event: any) => handleCheckbox(event.target.checked, index)} checked={category.status} />
                    </CategoriesGroup>

                ))
                }
            </CategoriesContainer>
            </ScrollCategory>

        </Container>
    )
}