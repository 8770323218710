import loadGif from "../../assets/dressmaker-lines.gif"

export default function Load() {
    return (

        <div style={{display: "flex",height: "70vh", width: "100vw", justifyContent: "center", alignItems: "center"}}>

            <img src={loadGif} alt="loading" />
        </div>
    )
}