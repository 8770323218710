import axios from 'axios'

export const setAuthorization = (token: string) => {

    api.interceptors.request.use((config) => {
      config.headers.Authorization = 'Bearer ' + token;
      return config;
    });
  
  }

const api = axios.create({
    //baseURL: 'http://localhost:3000/',
    baseURL: 'https://api.emporiotecidos.com.br/',
})

export default api