import styles from  "./styles.module.css"

export function Galeria({
    galeria
}: {
    galeria: any
}) {




    return(
        <div className={styles.imageContainer}>
            {
                galeria.map((img: any) => 

                    <div>
                        <img src={img.url} alt={img.posicao} className={styles.imgResponsiva} />
                    </div>

                )
            }
            
        </div>
    )
}