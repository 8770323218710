import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { Login } from "../screens/login";
import { Suspense, lazy } from "react";
import Load from "../screens/load";
import Categoria from "../screens/categoria";
import { Detalhes } from "../screens/detalhes";


const Liberacao = lazy(async () => {
    return Promise.all([
        import("../screens/liberacao"),
        new Promise(resolve => setTimeout(resolve, 3000))
    ])
        .then(([moduleExports]) => moduleExports);
})

export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="categoria/"
                    element={
                        <Suspense fallback={<Load />}>
                            <Categoria />
                        </Suspense>
                    }
                />
                <Route
                    path="liberacao/"
                    element={
                        <Suspense fallback={<Load />}>
                            <Liberacao />
                        </Suspense>
                    }
                />

                <Route
                    path="detalhar/:id"
                    element={
                        <Suspense fallback={<Load />}>
                            <Detalhes />
                        </Suspense>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}