import styled from "styled-components";

export const CategoriesContainer = styled.div`
    flex-direction: column;
    gap: 20px;
`

export const CategoriesGroup = styled.div`
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    border-bottom-width: 1px;
    border-style: dotted;
    border-color: #000;
    padding-bottom: 10px;
`

export const CategoryText = styled.p`
    font-size: 16px;
    color: #000
`

export const ScrollCategory = styled.div`
width: 100%;
padding: 0 20px;
`

export const Container = styled.div`
`
export const Title = styled.h2`
`